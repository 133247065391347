<template>
  <div class="paymentslink">
    <b-container>

      <!-- STATUS: NEW -->
      <validation-observer v-if="payment_get.status === 'NEW'" v-slot="{ invalid }" ref="observer">
        <b-row v-if="!completed && !purchasing">
          <b-col cols="12" class="text-center">
            <h2 class="vb-heading mb-4 pb-4" v-if="!completed && !purchasing">Effettua il pagamento</h2>
          </b-col>
          <b-col md="4" offset-md="1">
            <p><strong>Tipo di servizio:</strong> {{payment_get.productName}}</p>
            <p><strong>Descrizione del servizio:</strong> {{payment_get.description}}</p>
            <p class="paymentslink__price mt-4">Importo: € {{payment_get.live_price}}</p>
            <p class="mt-4">
              <strong class="d-block mb-2">Come effettuare il pagamento</strong>
              Compila il modulo che trovi in questa pagina. Una volta compilato correttamente in tutti i suoi campi
              ti verrà mostrato un modulo aggiuntivo in cui inserire i dati di carta di credito
            </p>
            <div class="paymentslink__cards">
              <vb-icon :name="'visa'" :size="30" />
              <vb-icon :name="'mastercard'" :size="30" />
              <vb-icon :name="'american-express'" :size="30" />
              <vb-icon :name="'dci'" :size="30" />
              <vb-icon :name="'discover'" :size="30" />
            </div>
            <div v-html="copy_security" />
          </b-col>
          <b-col md="5" offset-md="1">
            <h5 class="vb-heading" v-html="'Dati di fatturazione'" />
            <b-row>
              <b-col cols="12" sm="6" v-for="(field,key) in fields" v-bind:key="'input_'+key">
                <validation-provider :rules="field.rules" :name="field.name" :vid="key" v-slot="{ errors, validate }">
                  <div v-if="field.type == 'input'">
                    <label class="vb-label" v-html="field.name" />
                    <vb-input
                      v-model="fields[key].value"
                      :vb_options="{
                        name: key,
                        placeholder: field.name,
                        required: true,
                      }"/>
                    <span class="vee--errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </b-col>
            </b-row>
            <hr />
            <validation-provider
                :rules="privacy.rules"
                :name="privacy.name"
                :vid="privacy.name"
                class="d-block mb-3"
                v-slot="{ errors, validate }">
              <label class="semibold" v-html="privacy.name" />
              <vb-checkbox
                  v-model="privacy.value"
                  :vb_options="{
                    name: privacy.name,
                    text: privacy.text,
                  }"/>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider
                :rules="gdpr.rules"
                :name="gdpr.name"
                :vid="gdpr.name"
                class="d-block mb-3"
                v-slot="{ errors, validate }">
              <label class="semibold" v-html="gdpr.name" />
              <vb-checkbox
                  v-model="gdpr.value"
                  :vb_options="{
                    name: gdpr.name,
                    text: gdpr.text,
                  }"/>
              <span class="vee--errors">{{ errors[0] }}</span>
            </validation-provider>
            <stripe class="mt-5" :credit_card_amount="number(payment_get.live_price)" v-show="!invalid" @change="event => handle_stripe_update(event)" />
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col cols="12" v-if="purchasing" class="text-center">
            <loader>
              <template>
                <span>Pagamento in corso...<br>non abbandonare o ricaricare la pagina!</span>
              </template>
            </loader>
          </b-col>
          <b-col md="6" offset-md="3" v-else>
            <h2 class="vb-heading mb-4 pb-4" v-if="checkout_message">{{checkout_message}}</h2>
            <p><strong>Tipo di servizio:</strong> {{payment_get.productName}}</p>
            <p><strong>Descrizione del servizio:</strong> {{payment_get.description}}</p>
            <p class="paymentslink__price mt-4">Importo: € {{payment_get.live_price}}</p>
            <div v-html="copy_security" />
          </b-col>
        </b-row>
      </validation-observer>

      <!-- STATUS: COMPLETE -->
      <b-row v-if="payment_get.status === 'COMPLETE'">
        <b-col md="6" offset-md="3">
          <h2 class="vb-heading mb-4 pb-4" v-if="!completed && !purchasing">
            Questa pagina di pagamento non è più attiva<br>
            perchè il pagamento è stato già completato</h2>
          <p>
            <strong class="d-block mb-2">Perchè non posso effettuare il pagamento?</strong>
            Molto probabilmente hai già effettuato il pagamento.
          </p>
          <p class="mt-4">
            <strong class="d-block mb-2">Che cosa posso fare?</strong>
            Controlla la tua posta elettronica e verifica se hai ricevuto la mail di conferma del pagamento.
            Diversamente contatta la nostra assistenza ai riferimenti che trovi in questa stessa pagina.
          </p>
          <div v-html="copy_security" />
        </b-col>
      </b-row>

      <!-- STATUS: EXPIRED -->
      <b-row v-if="payment_get.status === 'EXPIRED'">
        <b-col md="6" offset-md="3">
          <h2 class="vb-heading mb-4 pb-4" v-if="!completed && !purchasing">Questa pagina di pagamento non è più attiva</h2>
          <p>
            <strong class="d-block mb-2">Perchè non posso effettuare il pagamento?</strong>
            Il pagamento del servizio aveva una scadenza temporale legata a disponibilità o altre condizioni:
            il termine è stato probabilmente superato.
          </p>
          <p class="mt-4">
            <strong class="d-block mb-2">Che cosa posso fare?</strong>
            Contatta la nostra assistenza ai contatti che trovi in questa stessa pagina.
          </p>
          <div v-html="copy_security" />
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import { number } from 'mathjs'
import { service_handler } from '@/services'
import Stripe from '@src/components/blocks/sidebar/sidebar-stripe'
import * as Validation from '@resources/validation'

export default {
  name: 'payments-link',

  components: {
    stripe: Stripe,
  },

  props: {
  },

  data() {
    return {
      fields: {
        first_name: Validation.first_name,
        last_name: Validation.last_name,
        fiscal_code: Validation.fiscal_code,
        phone: Validation.phone,
        address: Validation.address,
        city: Validation.city,
        province: Validation.province,
        postal_code: Validation.postal_code,
        email: Validation.email,
        email_conf: Validation.email_conf,
      },
      privacy: Validation.privacy,
      gdpr: Validation.gdpr,

      stripe_completed: false,
      purchasing: false,
      completed: false,
      checkout_message: '',

      pay_code: this.$route.params.pay_code,
      payment_link_id: this.$route.params.payment_link_id,
      payment_get: {},

      copy_security: `<hr class=my-4><p><strong class="d-block mb-2">Sicurezza e protezione dei tuoi dati</strong> Sappiamo quanto sia importante per te la sicurezza durante le transazioni online. Per questo motivo, utilizziamo i più avanzati standard di sicurezza per garantire che i tuoi dati personali e di pagamento siano sempre protetti.<ul class="px-4 text--sm"><li class=mb-3>Crittografia SSL (Secure Socket Layer): Tutti i dati sensibili inseriti in questa pagina, compresi i dettagli della tua carta di credito, sono criptati attraverso la tecnologia SSL, proteggendoli da accessi non autorizzati.<li class=mb-3>Certificazioni PCI DSS (Payment Card Industry Data Security Standard): Il nostro sistema di pagamento rispetta i rigorosi standard PCI DSS, assicurando che i dati della tua carta vengano gestiti e archiviati in modo sicuro.<li>Autenticazione 3D Secure: Quando applicabile, utilizziamo il sistema 3D Secure (ad esempio, "Verified by Visa" o "Mastercard SecureCode") per offrirti un ulteriore livello di protezione durante le transazioni.</ul><p>Puoi effettuare il pagamento con la massima tranquillità, sapendo che i tuoi dati sono protetti e che ci impegniamo a garantire la tua sicurezza online.<p>Per ulteriori informazioni sulla nostra politica di sicurezza contatta il nostro team di supporto.`
    }
  },

  computed: {
    payload() {
      return {
        token: this.$config.token,
        bookingData: {
          live_price: this.payment_get.live_price,
          payments: {
            /*
            fake: {
              amount: 0
            },*/
            cc: {
              amount: this.payment_get.live_price,
            }
          }
        },
        guestFirstName: this.fields.first_name.value,
        guestLastName: this.fields.last_name.value,
        guestEmail: this.fields.email.value,
        guestEmailConf: this.fields.email_conf.value,
        guestPhone: this.fields.phone.value,
        guestFiscalCode: this.fields.fiscal_code.value,

        guestAddress: (this.fields.address)? this.fields.address.value : '',
        guestCity: (this.fields.city)? this.fields.city.value : '',
        guestPostalCode: (this.fields.postal_code)? this.fields.postal_code.value : '',
        guestProvince: (this.fields.province)? this.fields.province.value : '',

        guestPrivacy: this.privacy.value,
      }
    }
  },

  created() {
    this.number = number
  },

  mounted() {
    this.pl_get()
  },

  methods: {
    handle_stripe_update(event) {
      if (event.name == 'stripe-init') {
        this.payload.bookingData.payments.cc = {
          amount: this.payment_get.live_price,
        }
        this.stripe_completed = false
      }
      if (event.name == 'stripe-change') {
        // no callback provided
      }
      if (event.name == 'stripe-confirm') {
        if (event.payload.error) {
          this.stripe_completed = false
          window.eventBus.$emit('got_modal_notification',{
            msg: event.payload.message,
            status: event.payload.status,
            callback: function() {}
          })
          return
        }
        this.payload.bookingData.payments.cc.paymentIntentId = event.payload.paymentIntentId
        this.payload.bookingData.payments.cc.nameSurname = event.payload.name
        this.payload.bookingData.payCode = this.pay_code
        this.payload.bookingData.paymentLinkId = this.payment_link_id

        this.stripe_completed = true

        this.pl_buy(this.payload)
      }
    },

    pl_get() {
      let params = {
        payCode:this.pay_code,
        paymentLinkId: this.payment_link_id
      }
      service_handler(
        'paymentsLink.get',
        params
      ).then(
        (success) => {
          this.payment_get = success.data.payment
        },
      )
    },

    pl_buy(params) {
      this.purchasing = true

      service_handler(
        'paymentsLink.buy',
        params
      ).then(
        (success) => {
          this.purchasing = false
          this.completed = true
          this.checkout_message = success.data.msg
          window.scrollTo(0,0)
        },
        (error) => {
          this.log(error.response)
          this.checkout_message = 'C\'è stato un problema'
          this.purchasing = false
        }
      )
    }
  }
}
</script>

<style lang="scss">
  .paymentslink {
    padding: $grid-gutter-width*2 0 $grid-gutter-width 0;

    &__price {
      font-size: 32px;
      font-weight: $vb-font-weight-semibold;
      color: $primary;
    }

    &__cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      svg {
        margin: 0 0.5rem;
        max-width: 40px;
        //filter: grayscale(100%);
      }
    }
  }

</style>